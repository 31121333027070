import { Pool } from '../constants/pool'
import { ChainId } from '@polydex/sdk'
import { SUBGRAPH_URL } from '../constants'
import { NETWORK_CHAIN_ID } from '../connectors'
import { PoolVersion } from './poolVersion'

export const fetchPool = async (poolId: string, chainId: ChainId = NETWORK_CHAIN_ID): Promise<Pool | null> => {
  const query = `
    {
      pool(id:"${poolId?.toLowerCase()}") {
        id
        swapFee
        totalWeight
        totalShares
        tokensList
        tokens(orderBy: "denormWeight", orderDirection: "desc") {
          address balance decimals name symbol denormWeight
        }
      }
    }
  `

  try {
    const response = await fetch(SUBGRAPH_URL[chainId], {
      method: 'POST',
      headers: { 'content-type': 'application/json', accept: 'application/json' },
      body: JSON.stringify({ query })
    })
    const { data } = await response.json()
    console.log('fetchPool', poolId, data)
    const result = (data && data.pool) || ({} as any)
    return { ...result, version: PoolVersion.StakePool }
  } catch (error) {}
  return null
}
