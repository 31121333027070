import { useEffect } from 'react'
import { SorProvider } from '../../modules/sor/src'
import DEFAULT_TOKEN_LIST from '../../constants/tokenLists/polydex-default.tokenlist.json'

export default function Updater(): null {
  useEffect(() => {
    try {
      const tokensList = DEFAULT_TOKEN_LIST.tokens?.map(t => {
        return t.address?.toLowerCase()
      })
      SorProvider.setWhiteListTokens(tokensList)
    } catch (e) {}
  }, [])

  return null
}
