import React, { useEffect, useRef, useState } from 'react'
import '../scss/TableRow.scss'
import ApiAxios from '../../../service/ApiAxios'
import TrRowTable from './TrRowTable'
const NUMBER_OF_POOLS_VISIBLE = 10

export default function TableRow(props: any) {
  const [poolInfos, setPoolInfos] = useState([])
  const loadMoreRef: any = useRef<HTMLDivElement>(null)
  const [numberOfPoolsVisible, setNumberOfPoolsVisible] = useState(NUMBER_OF_POOLS_VISIBLE)
  const [observerIsSet, setObserverIsSet] = useState(false)

  useEffect(() => {
    const showMorePools = (entries: any) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        setNumberOfPoolsVisible(poolsCurrentlyVisible => poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE)
      }
    }

    if (!observerIsSet) {
      const loadMoreObserver = new IntersectionObserver(showMorePools, {
        rootMargin: '0px',
        threshold: 1
      })
      loadMoreObserver.observe(loadMoreRef.current)
      setObserverIsSet(true)
    }
  }, [observerIsSet])

  const getPoolInfos = async () => {
    const res = await ApiAxios.LiquidityPool.getPoolInfos()
    setPoolInfos(res?.data)
  }

  useEffect(() => {
    getPoolInfos().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.searchValue])

  return (
    <div className="box-liquidity-pool-added">
      <div className="table-list-search">
        <div className="header-thead">
          <div className="label-header-column">Pair</div>
          <div className="label-header-column">My Liquidity</div>
          <div className="label-header-column">Liquidity</div>
          <div className="label-header-column">24h Volume</div>
          <div className="label-header-column-2" />
        </div>
        {poolInfos.length > 0 &&
          poolInfos.slice(0, numberOfPoolsVisible).map((item: any, key) => {
            return (
              <div className="tr-list-row-coin" key={key}>
                <TrRowTable item={item} searchValue={props?.searchValue} showBalance={props?.showBalance} />
              </div>
            )
          })}
        <div ref={loadMoreRef} />
      </div>
    </div>
  )
}
