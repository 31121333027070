import React, { useContext, useState } from 'react'
import { Token, Currency } from '@polydex/sdk'
import styled from 'styled-components'
import { ThemeContext } from 'styled-components'
import Card from 'components/Card'
import { AutoColumn } from 'components/Column'
import { RowBetween, RowFixed, AutoRow } from 'components/Row'
import CurrencyLogo from 'components/CurrencyLogo'
import { ArrowLeft, AlertTriangle } from 'react-feather'
import { transparentize } from 'polished'
import { SectionBreak } from 'components/swap/styleds'
import { useAddUserToken } from 'state/user/hooks'
import { getEtherscanLink } from 'utils'
import { useActiveWeb3React } from 'hooks'
import { ExternalLink } from '../../theme/components'
import { useCombinedInactiveList } from 'state/lists/hooks'
import ListLogo from 'components/ListLogo'
import { PaddedColumn, Checkbox } from './styleds'
import { Button, Text, CloseIcon } from '@polydex/uikit'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: auto;
`

const WarningWrapper = styled(Card)<{ highWarning: boolean }>`
  background-color: ${({ theme, highWarning }) =>
    highWarning ? transparentize(0.8, theme.colors.warning) : transparentize(0.8, theme.colors.warning)};
  width: fit-content;
`

interface ImportProps {
  tokens: Token[]
  onBack?: () => void
  onDismiss?: () => void
  handleCurrencySelect?: (currency: Currency) => void
}

export function ImportToken({ tokens, onBack, onDismiss, handleCurrencySelect }: ImportProps) {
  const theme = useContext(ThemeContext)

  const { chainId } = useActiveWeb3React()

  const [confirmed, setConfirmed] = useState(false)

  const addToken = useAddUserToken()

  // use for showing import source on inactive tokens
  const inactiveTokenList = useCombinedInactiveList()

  // higher warning severity if either is not on a list
  const fromLists =
    (chainId && inactiveTokenList?.[chainId]?.[tokens[0]?.address]?.list) ||
    (chainId && inactiveTokenList?.[chainId]?.[tokens[1]?.address]?.list)

  return (
    <Wrapper>
      <PaddedColumn gap="14px" style={{ width: '100%', flex: '1 1' }}>
        <RowBetween>
          {onBack ? <ArrowLeft className="text-white" style={{ cursor: 'pointer' }} onClick={onBack} /> : <div></div>}
          <div className="text-white text-header-modal-import-token">
            Import {tokens.length > 1 ? 'Tokens' : 'Token'}
          </div>
          {onDismiss ? <CloseIcon className="text-white" onClick={onDismiss} /> : <div></div>}
        </RowBetween>
      </PaddedColumn>
      <SectionBreak />
      <PaddedColumn gap="md">
        {tokens.map(token => {
          const list = chainId && inactiveTokenList?.[chainId]?.[token.address]?.list
          return (
            <Card backgroundColor={theme?.bg2} key={'import' + token.address} className=".token-warning-container">
              <AutoColumn gap="10px">
                <AutoRow align="center">
                  <CurrencyLogo currency={token} size={'24px'} />
                  <Text ml="8px" mr="8px" fontWeight={500}>
                    {token.symbol}
                  </Text>
                  <Text fontWeight={300}>{token.name}</Text>
                </AutoRow>
                {chainId && (
                  <ExternalLink href={getEtherscanLink(chainId, token.address, 'address')}>
                    <Text color="blue">{token.address}</Text>
                  </ExternalLink>
                )}
                {list !== undefined ? (
                  <RowFixed>
                    {list.logoURI && <ListLogo logoURI={list.logoURI} size="12px" />}
                    <Text ml="6px">via {list.name}</Text>
                  </RowFixed>
                ) : (
                  <WarningWrapper borderRadius="4px" padding="4px" highWarning={true}>
                    <RowFixed>
                      <AlertTriangle stroke={theme.colors.failure} size="10px" />
                      <Text color="red" ml="4px" fontSize="10px" fontWeight={500}>
                        Unknown Source
                      </Text>
                    </RowFixed>
                  </WarningWrapper>
                )}
              </AutoColumn>
            </Card>
          )
        })}
        <div className="mg-top-10" />
        <Card
          style={{ backgroundColor: fromLists ? transparentize(0.8, theme?.yellow2) : transparentize(0.8, theme.colors.failure) }}
        >
          <AutoColumn justify="center" style={{ textAlign: 'center', gap: '16px', marginBottom: '12px' }}>
            <AlertTriangle stroke={fromLists ? theme?.yellow2 : theme.colors.failure} size={32} />
            <div className="text-white">Trade at your own risk!</div>
          </AutoColumn>

          <AutoColumn style={{ textAlign: 'center', gap: '16px', marginBottom: '12px' }}>
            <div className="text-white">
              Anyone can create a token, including creating fake versions of existing tokens that claim to represent
              projects.
            </div>
            <div className="text-white">If you purchase this token, you may not be able to sell it back.</div>
          </AutoColumn>
          <AutoRow justify="center" style={{ cursor: 'pointer' }} onClick={() => setConfirmed(!confirmed)}>
            <Checkbox
              className=".understand-checkbox"
              name="confirmed"
              type="checkbox"
              checked={confirmed}
              onChange={() => setConfirmed(!confirmed)}
            />
            <div className="text-white">I understand</div>
          </AutoRow>
        </Card>
        <div className="mg-top-10" />
        <Button
          disabled={!confirmed}
          onClick={() => {
            tokens.map(token => addToken(token))
            handleCurrencySelect && handleCurrencySelect(tokens[0])
          }}
        >
          Import
        </Button>
      </PaddedColumn>
    </Wrapper>
  )
}
