import { isTradeBetter } from 'utils/trades'
import { ChainId, Currency, CurrencyAmount, Pair, Percent, Route, Token, TokenAmount, Trade, TradeType } from '@polydex/sdk'
import flatMap from 'lodash.flatmap'
import { useMemo } from 'react'

import {
  ADDITIONAL_BASES,
  BASES_TO_CHECK_TRADES_AGAINST,
  BETTER_TRADE_LESS_HOPS_THRESHOLD,
  CUSTOM_BASES
} from '../constants'
import { PairState, usePairs } from '../data/Reserves'
import { wrappedCurrency } from '../utils/wrappedCurrency'

import { useActiveWeb3React } from './index'
import { useUnsupportedTokens } from './Tokens'
import { useUserSingleHopOnly } from 'state/user/hooks'
import BigNumber from 'bignumber.js'
import JSBI from 'jsbi'
import { bnum } from 'utils/number'
import { ChartData, ChartSwap, SwapRoutePool } from 'state/swap/actions'
import { isAddressEqual } from 'utils'

function useAllCommonPairs(currencyA?: Currency, currencyB?: Currency): Pair[] {
  const { chainId } = useActiveWeb3React()

  const [tokenA, tokenB] = chainId
    ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
    : [undefined, undefined]

  const bases: Token[] = useMemo(() => {
    if (!chainId) return []

    const common = BASES_TO_CHECK_TRADES_AGAINST[chainId] ?? []
    const additionalA = tokenA ? ADDITIONAL_BASES[chainId]?.[tokenA.address] ?? [] : []
    const additionalB = tokenB ? ADDITIONAL_BASES[chainId]?.[tokenB.address] ?? [] : []

    return [...common, ...additionalA, ...additionalB]
  }, [chainId, tokenA, tokenB])

  const basePairs: [Token, Token][] = useMemo(
    () => flatMap(bases, (base): [Token, Token][] => bases.map(otherBase => [base, otherBase])),
    [bases]
  )

  const allPairCombinations: [Token, Token][] = useMemo(
    () =>
      tokenA && tokenB
        ? [
            // the direct pair
            [tokenA, tokenB],
            // token A against all bases
            ...bases.map((base): [Token, Token] => [tokenA, base]),
            // token B against all bases
            ...bases.map((base): [Token, Token] => [tokenB, base]),
            // each base against all bases
            ...basePairs
          ]
            .filter((tokens): tokens is [Token, Token] => Boolean(tokens[0] && tokens[1]))
            .filter(([t0, t1]) => t0.address !== t1.address)
            .filter(([tokenA, tokenB]) => {
              if (!chainId) return true
              const customBases = CUSTOM_BASES[chainId]

              const customBasesA: Token[] | undefined = customBases?.[tokenA.address]
              const customBasesB: Token[] | undefined = customBases?.[tokenB.address]

              if (!customBasesA && !customBasesB) return true

              if (customBasesA && !customBasesA.find(base => tokenB.equals(base))) return false
              if (customBasesB && !customBasesB.find(base => tokenA.equals(base))) return false

              return true
            })
        : [],
    [tokenA, tokenB, bases, basePairs, chainId]
  )

  const allPairs = usePairs(allPairCombinations)

  // only pass along valid pairs, non-duplicated pairs
  return useMemo(
    () =>
      Object.values(
        allPairs
          // filter out invalid pairs
          .filter((result): result is [PairState.EXISTS, Pair] => Boolean(result[0] === PairState.EXISTS && result[1]))
          // filter out duplicated pairs
          .reduce<{ [pairAddress: string]: Pair }>((memo, [, curr]) => {
            memo[curr.liquidityToken.address] = memo[curr.liquidityToken.address] ?? curr
            return memo
          }, {})
      ),
    [allPairs]
  )
}

const MAX_HOPS = 3

/**
 * Returns the best trade for the exact amount of tokens in to the given token out
 */
export function useTradeExactIn(currencyAmountIn?: CurrencyAmount, currencyOut?: Currency): Trade | null {
  const allowedPairs = useAllCommonPairs(currencyAmountIn?.currency, currencyOut)

  const [singleHopOnly] = useUserSingleHopOnly()

  return useMemo(() => {
    if (currencyAmountIn && currencyOut && allowedPairs.length > 0) {
      if (singleHopOnly) {
        return (
          Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, { maxHops: 1, maxNumResults: 1 })[0] ??
          null
        )
      }
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade | null = null
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade: Trade | null =
          Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, { maxHops: i, maxNumResults: 1 })[0] ??
          null
        // if current trade is best yet, save it
        if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
          bestTradeSoFar = currentTrade
        }
      }
      return bestTradeSoFar
    }

    return null
  }, [allowedPairs, currencyAmountIn, currencyOut, singleHopOnly])
}

/**
 * Returns the best trade for the token in to the exact amount of token out
 */
export function useTradeExactOut(currencyIn?: Currency, currencyAmountOut?: CurrencyAmount): Trade | null {
  const allowedPairs = useAllCommonPairs(currencyIn, currencyAmountOut?.currency)

  const [singleHopOnly] = useUserSingleHopOnly()

  return useMemo(() => {
    if (currencyIn && currencyAmountOut && allowedPairs.length > 0) {
      if (singleHopOnly) {
        return (
          Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, { maxHops: 1, maxNumResults: 1 })[0] ??
          null
        )
      }
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade | null = null
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade =
          Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, { maxHops: i, maxNumResults: 1 })[0] ??
          null
        if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
          bestTradeSoFar = currentTrade
        }
      }
      return bestTradeSoFar
    }
    return null
  }, [currencyIn, currencyAmountOut, allowedPairs, singleHopOnly])
}

export function useIsTransactionUnsupported(currencyIn?: Currency, currencyOut?: Currency): boolean {
  const unsupportedTokens: { [address: string]: Token } = useUnsupportedTokens()
  const { chainId } = useActiveWeb3React()

  const tokenIn = wrappedCurrency(currencyIn, chainId)
  const tokenOut = wrappedCurrency(currencyOut, chainId)

  // if unsupported list loaded & either token on list, mark as unsupported
  if (unsupportedTokens) {
    if (tokenIn && Object.keys(unsupportedTokens).includes(tokenIn.address)) {
      return true
    }
    if (tokenOut && Object.keys(unsupportedTokens).includes(tokenOut.address)) {
      return true
    }
  }

  return false
}

export function convertSorToTrade(
  chainId: ChainId,
  isExactIn: boolean,
  inputCurrencyId: string | undefined,
  outputCurrencyId: string | undefined,
  inputCurrency: Currency | undefined,
  outputCurrency: Currency | undefined,
  currencyAmount: CurrencyAmount | undefined,
  totalReturn: BigNumber,
  sorSwaps: any[][],
  expectedSlippage: BigNumber
): Trade | null {
  if (!currencyAmount || !inputCurrency || !outputCurrency) {
    return null
  }
  const amount = currencyAmount ? currencyAmount.raw.toString() : '0'
  try {
    const tokenIn = wrappedCurrency(inputCurrency, chainId) || ({} as Token)
    const tokenOut = wrappedCurrency(outputCurrency, chainId) || ({} as Token)
    const pairs: Pair[] = []
    if (Array.isArray(sorSwaps)) {
      // sorSwaps.forEach(sor => {
      //   const pair = new Pair(new TokenAmount())
      // })
      const pair = new Pair(
        new TokenAmount(tokenIn, isExactIn ? amount : totalReturn.toString()),
        new TokenAmount(tokenOut, isExactIn ? totalReturn.toString() : amount)
      )
      pairs.push(pair)
    }
    console.log('expectedSlippage', expectedSlippage.toString())
    const numerator = expectedSlippage.decimalPlaces(2).multipliedBy(100)
    const overrides: any = {
      swapSequences: sorSwaps,
      tokenIn,
      tokenOut,
      priceImpact: new Percent(numerator.toFixed(), JSBI.BigInt(10000))
    }
    if (isExactIn) {
      overrides.outputAmount = new TokenAmount(tokenOut, totalReturn.toString())
    } else {
      overrides.inputAmount = new TokenAmount(tokenIn, totalReturn.toString())
    }

    const route = new Route(pairs, inputCurrency, outputCurrency)
    return new Trade(route, currencyAmount, isExactIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT, overrides)
  } catch (e) {
    console.error('__sor_to_trade__', e)
  }
  return null
}

export const useTradeComposition = (
  tradeType: TradeType,
  formattedSorSwaps: any[],
  inputCurrencyId: string | undefined,
  currencyAmount: CurrencyAmount | undefined,
  totalReturn: BigNumber,
  validSwap: boolean
) => {
  const result: ChartData = {
    validSwap: true,
    inputPriceValue: bnum(0),
    outputPriceValue: bnum(0),
    swaps: [],
    noPools: 0,
    routes: []
  }

  if (!validSwap) {
    result.validSwap = false
  }

  const others: ChartSwap = {
    isOthers: true,
    percentage: 0
  }

  const inputValue = bnum(currencyAmount?.raw.toString() || 0)

  const calcSwapPercentage = (inAddress: string, swapAmount: string) => {
    return isAddressEqual(inAddress, inputCurrencyId) && inputValue?.gt(0)
      ? bnum(swapAmount || 0)
          .div(inputValue)
          .multipliedBy(100)
          .toNumber()
      : undefined
  }

  const tempChartSwaps: ChartSwap[] = []
  // Convert all Swaps to ChartSwaps
  formattedSorSwaps.forEach((sorMultiSwap: any) => {
    if (sorMultiSwap.sequence.length === 1) {
      const swap = sorMultiSwap.sequence[0]

      tempChartSwaps.push({
        isOthers: false,
        firstPoolAddress: swap.pool,
        secondPoolAddress: undefined,
        percentage: bnum(swap.swapAmount)
          .div(inputValue)
          .times(100)
          .dp(2, BigNumber.ROUND_HALF_EVEN)
          .toNumber(),
        noPools: 1
      })
      // route
      result.routes.push({
        pools: [
          {
            id: swap.pool,
            version: swap.version,
            swapAmount: swap.swapAmount,
            swapPercentage: calcSwapPercentage(swap.tokenIn?.address, swap.swapAmount)
          }
        ],
        path: [swap.tokenIn, swap.tokenOut],
        version: swap.version
      })
    } else if (sorMultiSwap.sequence.length > 1) {
      const swapFirst = sorMultiSwap.sequence[0]
      const swapSecond = sorMultiSwap.sequence[1]

      const swapValue = tradeType === TradeType.EXACT_INPUT ? swapFirst.swapAmount : swapSecond.swapAmount

      tempChartSwaps.push({
        isOthers: false,
        firstPoolAddress: swapFirst.pool,
        secondPoolAddress: swapSecond.pool,
        percentage: bnum(swapValue)
          .div(inputValue)
          .times(100)
          .dp(2, BigNumber.ROUND_HALF_EVEN)
          .toNumber(),
        noPools: 2
      })
      // route
      const path: any[] = []
      const pools: SwapRoutePool[] = []
      sorMultiSwap.sequence.forEach((swap: any, i: number) => {
        pools.push({
          id: swap.pool,
          version: swap.version,
          swapAmount: swap.swapAmount,
          swapPercentage: i === 0 ? calcSwapPercentage(swap.tokenIn?.address, swap.swapAmount) : 100
        })
        if (i === 0) {
          path.push(swap.tokenIn)
        }
        path.push(swap.tokenOut)
      })
      result.routes.push({ path, pools })
    }
  })

  let totalPercentage = new BigNumber(0)

  tempChartSwaps.forEach((value, index) => {
    if (index === 0 || index === 1 || index === 2) {
      result.swaps.push(value)
      result.noPools = (result.noPools || 0) + (value.noPools || 0)
    } else {
      others.percentage += value.percentage
      result.noPools = (result.noPools || 0) + 1
    }

    totalPercentage = totalPercentage.plus(value.percentage)
  })

  if (others.percentage > 0) {
    result.swaps.push(others)
  }

  if (tradeType === TradeType.EXACT_INPUT) {
    result.inputPriceValue = inputValue
    result.outputPriceValue = totalReturn
  } else if (tradeType === TradeType.EXACT_OUTPUT) {
    result.inputPriceValue = totalReturn
    result.outputPriceValue = inputValue
  }

  return result
}
