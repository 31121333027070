import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import { Link as HistoryLink, NavLink } from 'react-router-dom'

import { ArrowLeft } from 'react-feather'
import { RowBetween } from '../Row'
// import QuestionHelper from '../QuestionHelper'
// import Settings from '../Settings'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { resetMintState } from 'state/mint/actions'
import QuestionHelper from 'components/QuestionHelper'

const Tabs = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
`

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 20px;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 3rem;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 20px;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.text1};
`

export function SwapPoolTabs({ active }: { active: 'swap' | 'pool' }) {
  const { t } = useTranslation()
  return (
    <>
      <div className="box-header-SwapPoolTabs">
        <Tabs style={{ marginBottom: '20px', display: 'none' }}>
          <StyledNavLink id={`swap-nav-link`} to={'/swap'} isActive={() => active === 'swap'}>
            {t('swap')}
          </StyledNavLink>
          <StyledNavLink id={`pool-nav-link`} to={'/pool'} isActive={() => active === 'pool'}>
            {t('pool')}
          </StyledNavLink>
        </Tabs>
      </div>
    </>
  )
}

export function FindPoolTabs() {
  return (
    <>
      <div className="box-header-FindPoolTabs">
        <Tabs>
          <RowBetween>
            <HistoryLink to="/liquidity">
              <StyledArrowLeft />
            </HistoryLink>
            <ActiveText>Import Pool</ActiveText>
          </RowBetween>
        </Tabs>
      </div>
    </>
  )
}

export function AddRemoveTabs({ adding, creating }: { adding: boolean; creating: boolean }) {
  // reset states on back
  const dispatch = useDispatch<AppDispatch>()

  return (
    <Tabs>
      <RowBetween style={{ padding: '1em 24px 1em 24px' }}>
        <HistoryLink
          to="/liquidity"
          onClick={() => {
            adding && dispatch(resetMintState())
          }}
        >
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>{adding ? 'Add' : 'Remove'} Liquidity</ActiveText>
        <QuestionHelper
          text={
            adding
              ? 'When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time.'
              : 'Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.'
          }
        />
      </RowBetween>
    </Tabs>
  )
}

export function CreatePairTabs() {
  return (
    <Tabs>
      <RowBetween style={{ padding: '2rem' }}>
        <HistoryLink to={'/liquidity'}>
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>{'Create a pair'}</ActiveText>
        <QuestionHelper
          text={'You are the first liquidity provider. The ratio of tokens you add will set the price of this pool.'}
        />
      </RowBetween>
    </Tabs>
  )
}
