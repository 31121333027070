import { ChainId, Currency } from '@polydex/sdk'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Button, LinkExternal } from '@polydex/uikit'
import { ArrowUpCircle, CheckCircle } from 'react-feather'
import { AutoColumn } from '../Column'
import { getEtherscanLink } from '../../utils'
import { Wrapper, Section, ConfirmedIcon, ContentHeader } from './helpers'
import useAddTokenToMetamask from '../../hooks/useAddTokenToMetamask'
import { useActiveWeb3React } from '../../hooks'
import { RowFixed } from '../Row'
import MetaMaskLogo from '../../assets/images/metamask.png'

const StyledLogo = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 6px;
`

type TransactionSubmittedContentProps = {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: Currency | undefined
}

const TransactionSubmittedContent = ({ onDismiss, chainId, hash, currencyToAdd }: TransactionSubmittedContentProps) => {
  const theme = useContext(ThemeContext)
  const { library } = useActiveWeb3React()
  const { addToken, success } = useAddTokenToMetamask(currencyToAdd)
  return (
    <Wrapper>
      <Section>
        <ContentHeader onDismiss={onDismiss}>Transaction submitted</ContentHeader>
        <ConfirmedIcon>
          <ArrowUpCircle strokeWidth={0.5} size={97} color={theme.colors.primary} />
        </ConfirmedIcon>
        <AutoColumn gap="8px" justify="center">
          {chainId && hash && (
            <LinkExternal href={getEtherscanLink(chainId, hash, 'transaction')}>View on Celo Explorer</LinkExternal>
          )}
          {currencyToAdd && library?.provider?.isMetaMask && currencyToAdd?.symbol?.toUpperCase() !== 'MATIC' && (
            <Button style={{ width: '100%' }} onClick={addToken}>
              {!success ? (
                <RowFixed margin={'auto'}>
                  Add {currencyToAdd.symbol} to Metamask <StyledLogo src={MetaMaskLogo} />
                </RowFixed>
              ) : (
                <RowFixed margin={'auto'}>
                  Added {currencyToAdd.symbol}{' '}
                  <CheckCircle size={'16px'} className="text-white" style={{ marginLeft: '6px' }} />
                </RowFixed>
              )}
            </Button>
          )}
          <Button onClick={onDismiss} mt="20px">
            Close
          </Button>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export default TransactionSubmittedContent
