import { Trade } from '@polydex/sdk'
import React, { Fragment, memo, useContext } from 'react'
import { ChevronRight } from 'react-feather'
import { Flex, Text } from '@polydex/uikit'
import { ThemeContext } from 'styled-components'
import { unwrappedToken } from 'utils/wrappedCurrency'

export default memo(function SwapRoute({ trade }: { trade: Trade }) {
  const theme = useContext(ThemeContext)
  return (
    <Flex
      flexWrap="wrap"
      width="100%"
      justifyContent="flex-end"
      style={{ border: `1px solid ${theme.colors.tertiary}`, borderRadius: '1rem' }}
      alignItems="center"
    >
      {trade.route.path.map((token, i, path) => {
        const isLastItem: boolean = i === path.length - 1
        const currency = unwrappedToken(token)
        return (
          <Fragment key={i}>
            <Flex alignItems="end">
              <Text fontSize="14px" color="text" ml="0.5rem">
                {currency.symbol}
              </Text>
            </Flex>
            {isLastItem ? null : <ChevronRight color="textSubtle" />}
          </Fragment>
        )
      })}
    </Flex>
  )
})
