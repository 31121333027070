import LiquidityPoolService from './LiquidityPoolService'

const ApiAxios = {
  LiquidityPool: new LiquidityPoolService()
  // EtInformation: new EtInformation(),
  // EtPart: new EtPart(),
  // EtQuestion: new EtQuestion(),
  // EtCategory: new EtCategory(),
  // UploadFile: new UploadFile(),
}

export default ApiAxios
