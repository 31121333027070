import React from 'react'
import CurrencyLogo from '../../../components/CurrencyLogo'
import { Field } from '../../../state/swap/actions'
import Web3 from 'web3'
import { ChainId, Token } from '@polydex/sdk'
import arrowRight from '../../../assets/images/right-arrow.svg'
import DoubleCurrencyLogo from '../../../components/DoubleLogo'
import AppBody from '../../AppBody'
import { Heading, Text } from '@polydex/uikit'

export default function RouterCoin(props: any) {
  const currencyInput = props?.currencies[Field.INPUT]
  const currencyOutput = props?.currencies[Field.OUTPUT]

  const swaps = props?.tradeComposition?.swaps

  const routerList = (item: any, key: number) => {
    return (
      <div className="row-router-switch-coin" key={key}>
        <div className="percent-switch">
          {swaps && swaps[key].percentage ? `${swaps[key].percentage.toFixed(0)}%` : ''}
        </div>
        <img src={arrowRight} className="arrow-right-token-first" alt="" />
        <div className="box-switch-icon-two-coin">
          <div className={`box-list-item-coin`}>
            {item.map((value: any, keyValue: number) => {
              const urlIn = Web3.utils.toChecksumAddress(value?.tokenIn)
              const urlOut = Web3.utils.toChecksumAddress(value?.tokenOut)
              const tokenIn = new Token(ChainId.MAINNET, urlIn, 18)
              const tokenOut = new Token(ChainId.MAINNET, urlOut, 18)
              return (
                <div className={`item-coin ${item.length > 1 && keyValue > 0 ? 'arrow-center' : ''}`} key={keyValue}>
                  <div className="box-item-content">
                    <div className="header-row-coin">
                      <DoubleCurrencyLogo currency0={tokenIn} currency1={tokenOut} size={24} />
                    </div>
                    {value.version === 3051 ? (
                      <label className="label-bottom-coin">UbeSwap</label>
                    ) : value.version === 4051 ? (
                      <label className="label-bottom-coin">SushiSwap</label>
                    ) : value.version === 3001 ? (
                      <label className="label-bottom-coin">PolyDex</label>
                    ) : value.version === 4052 ? (
                      <label className="label-bottom-coin">FireBird</label>
                    ) : null}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  return (
    <AppBody>
      <div className="router-coin">
        <div className="bg-router-coin">
          <Heading mb="8px">Routing</Heading>
          <div className="title-header-token">
            <Text fontSize={'11px'}>Input</Text>
            <Text fontSize={'11px'}>Output</Text>
          </div>
          <div className="content-body">
            <div className="item-router-1">
              <div className="image-style-router">
                <CurrencyLogo currency={currencyInput} size={'24px'} />
              </div>
              {/*<Text>
                {(currencyInput && currencyInput.symbol && currencyInput.symbol.length > 20
                  ? currencyInput.symbol.slice(0, 4) +
                  '...' +
                  currencyInput.symbol.slice(currencyInput.symbol.length - 5, currencyInput.symbol.length)
                  : currencyInput?.symbol) || ''}
              </Text>*/}
            </div>

            <div className="item-router-2">
              {props?.swapSequences && props?.swapSequences.length > 0
                ? props?.swapSequences.map((item: any, key: number) => {
                    return routerList(item, key)
                  })
                : null}
            </div>
            {currencyOutput ? (
              <div className="item-router-3">
                <div className="image-style-router">
                  <CurrencyLogo currency={currencyOutput} size={'24px'} />
                </div>
                {/*<Text>
                  {(currencyOutput && currencyOutput.symbol && currencyOutput.symbol.length > 20
                    ? currencyOutput.symbol.slice(0, 4) +
                    '...' +
                    currencyOutput.symbol.slice(currencyOutput.symbol.length - 5, currencyOutput.symbol.length)
                    : currencyOutput?.symbol) || ''}
                </Text>*/}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </AppBody>
  )
}
