import { axiosGET } from './TypeAxios'
require('dotenv').config()

export default class LiquidityPoolService {
  async getPoolInfos() {
    // if (Object.values(params).length > 0) {
    //   url += makeUrl(params)
    // }
    return await axiosGET('https://celo-api.polydex.fi/v1/subgraph-pools', {})
  }
  async scanPool() {
    return await axiosGET(`https://celo-api.polydex.fi/v1/scan/pools`, {})
  }
}
