import { ethers } from 'ethers'
import SorProvider, { PoolVersion } from './app'
import HardcodeUniPairs from './hardcode-quickswap-pairs.json'
import HardcodeSushiPairs from './hardcode-sushiswap-pairs.json'
import HardcodeDfynPairs from './hardcode-dfyn-pairs.json'
import HardcodeFireBirdPairs from './hardcode-firebird-pairs.json'
import HardcodePolydexPairs from './hardcode-polydex-pairs.json'

function getSubgraphUrl() {
  return SorProvider.getSubgraphUrl()
}

// LEGACY FUNCTION - Keep Input/Output Format
export async function getPoolsWithTokens(tokenIn: string, tokenOut: string) {
  // GraphQL is case-sensitive
  // Always use checksum addresses
  tokenIn = ethers.utils.getAddress(tokenIn)
  tokenOut = ethers.utils.getAddress(tokenOut)

  const query = `
      query ($tokens: [Bytes!]) {
          pools (first: 1000, where: {tokensList_contains: $tokens, active: true}) {
            id
            swapFee
            totalWeight
            tokensList
            tokens {
              id
              address
              balance
              decimals
              symbol
              denormWeight
            }
          }
        }
    `

  const variables = {
    tokens: [tokenIn, tokenOut]
  }
  const response = await fetch(getSubgraphUrl(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query,
      variables
    })
  })
  console.log('getPoolsWithTokens', tokenIn, tokenOut, getSubgraphUrl(), response)
  const { data } = await response.json()
  return data
}

// LEGACY FUNCTION - Keep Input/Output Format
export async function getTokenPairs(token: string) {
  // GraphQL is case-sensitive
  // Always use checksum addresses
  token = ethers.utils.getAddress(token)

  const query = `
      query ($token: [Bytes!]) {
          pools (first: 1000, where: {tokensList_contains: $token, active: true}) {
            tokensList
          }
        }
    `

  const variables = {
    token: [token]
  }

  const response = await fetch(getSubgraphUrl(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query,
      variables
    })
  })

  const { data } = await response.json()
  return data
}

export async function getAllPublicQuickSwapPairs(): Promise<{ pools: any }> {
  const url = 'https://api.thegraph.com/subgraphs/name/jiro-ono/sushitestsubgraph';
  console.log('url', url)
  if (!url) {
    return { pools: [] }
  }

  const query = `
      {
          pairs(first: 100, orderBy: reserveUSD, orderDirection: desc) {
            id
            token0 {
              address: id
              balance:
              decimals
              symbol
            }
            token1 {
              address: id
              balance:
              decimals
              symbol
            }
            totalShares: totalSupply
          }
      }
    `

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query
      })
    })
    console.log('response', response)
    const { data } = await response.json()
    console.log('data', data)
    let pools = []
    if (data) {
      const { pairs } = data
      if (Array.isArray(pairs) && pairs.length) {
        pools = pairs.map(p => {
          const { token0 = {}, token1 = {}, ...rest } = p
          const tokensList = [token0.address, token1.address]
          const tokens = [
            Object.assign({}, token0, {
              id: tokensList.join('-'),
              denormWeight: 50
            }),
            Object.assign({}, token1, {
              id: tokensList.join('-'),
              denormWeight: 50
            })
          ]
          return Object.assign({}, rest, {
            swapFee: 0.003, // 0.3%
            totalWeight: 100,
            tokens,
            tokensList,
            version: PoolVersion.UniPair
          })
        })
      }
    }
    console.log('getAllPublicQuickSwapPairs', pools)
    return { pools }
  } catch (e) {
    console.error('[getAllPublicQuickSwapPairs]', e)
    return { pools: [] }
  }
}

export async function getHardCodePolydexPairs(): Promise<{ pools: any }> {
  let pools = HardcodePolydexPairs;
  return { pools }
}

export async function getHardCodeUbeSwapPairs(): Promise<{ pools: any }> {
  let pools = HardcodeUniPairs;
  return { pools }
}

export async function getHardCodeSushiSwapPairs(): Promise<{ pools: any }> {
  let pools = HardcodeSushiPairs;
  return { pools }
}

export async function getHardCodeDfynPairs(): Promise<{ pools: any }> {
  let pools = HardcodeDfynPairs;
  return { pools }
}

export async function getHardCodeFireBirdPairs(): Promise<{ pools: any }> {
  let pools = HardcodeFireBirdPairs;
  return { pools }
}

// Returns all public pools
export async function getAllPublicPolydexPairs() {
  // const minLiquidity = SorProvider.isMainnet() ? 10 : -1
  const minLiquidity = -1
  const query = `
      {
          pools (first: 1000, where: {liquidity_gt: ${minLiquidity}}, orderBy: liquidity, orderDirection: desc) {
            id
            swapFee
            totalWeight
            tokens (orderBy: "denormWeight", orderDirection: "desc") {
              id
              address
              balance
              decimals
              symbol
              denormWeight
            }
            tokensList
            totalShares
          }
      }
    `

  const response = await fetch(getSubgraphUrl(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query
    })
  })
  console.log('getAllPublicPolydexPairs', getSubgraphUrl(), response)
  const { data } = await response.json()
  const pools = Array.isArray(data?.pools) ? data.pools : []
  if (pools.length) {
    pools.forEach((p: any) => {
      p.version = 3001
    })
  }
  console.log('polydex', pools)
  return { pools }
}

// Returns all public pools
export async function getAllPublicSwapPools() {
  let mode = 'live';
  const [polydexPairs, quickswapPairs, sushiPairs] = await Promise.all([
    mode ==='hardcoded' ? getHardCodePolydexPairs(): getAllPublicPolydexPairs(),
    getHardCodeUbeSwapPairs(),
    getHardCodeSushiSwapPairs()
  ])
  console.log('polydexPairs', polydexPairs.pools)
  console.log('quickswapPairs', quickswapPairs.pools.length)
  console.log('sushiPairs', sushiPairs.pools.length)
  return {
    pools: [].concat(polydexPairs?.pools || [])
      .concat(quickswapPairs?.pools || [])
      .concat(sushiPairs?.pools || [])
      // .concat(dfynPairs?.pools || [])
      // .concat(fPairs?.pools || [])
  }
}
