const SUBGRAPH_URL =
  process.env.REACT_APP_SUBGRAPH_URL ||
  'https://api.thegraph.com/subgraphs/name/polydex-ws/polydex-celo';

const UBE_SUBGRAPH_URL =
  'https://api.thegraph.com/subgraphs/name/ubeswap/ubeswap-backup';

export interface DeflatingToken {
  address: string;
  deflatingFactor: number;
}

export interface WhitelistToken {
  address: string;
}

export const PoolVersion = {
  BPool: 1001,
  FaaSPool: 2001,
  StakePool: 3001,
  UniPair: 3051,
  DfynPair: 4051,
  Firebird: 4052,
  StableSwapPool: 5001,
};

const ChainId = {
  MAINNET: 42220,
  KOVAN: 80001,
};

class SorProvider {
  chainId: number;
  subgraphUrl: string;
  ubeSubgraphUrl: string;
  deflatingTokens: DeflatingToken[];
  whiteListTokens: string[];

  constructor() {
    this.chainId = ChainId.MAINNET;
    this.subgraphUrl = SUBGRAPH_URL;
    this.ubeSubgraphUrl = UBE_SUBGRAPH_URL;
    this.deflatingTokens = [];
    this.whiteListTokens = [];
  }

  /**
   * chainId
   * */
  getChainId(): number {
    return this.chainId;
  }

  setChainId(chainId: number): void {
    this.chainId = chainId;
  }

  isMainnet(): boolean {
    return this.chainId === ChainId.MAINNET;
  }

  /**
   * subgraphUrl
   * */
  getSubgraphUrl(): string {
    return this.subgraphUrl;
  }

  setSubgraphUrl(url: string): void {
    this.subgraphUrl = url;
  }

  /**
   * deflatingTokens
   * */
  getDeflatingTokens(): DeflatingToken[] {
    return this.deflatingTokens;
  }

  setDeflatingTokens(tokens: DeflatingToken[]): void {
    this.deflatingTokens = tokens;
  }

  getWhiteListTokens(): string[] {
    return this.whiteListTokens;
  }

  setWhiteListTokens(tokens: string[]): void {
    this.whiteListTokens = tokens;
  }

  getUbeSwapSubgraphUrl(): string {
    return this.ubeSubgraphUrl || '';
  }

}

export default new SorProvider();
