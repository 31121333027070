import React, { useState } from 'react'
import TableRow from './Component/TableRow'
import './scss/SearchNewPair.scss'
import SearchInput from './Component/SearchInput'
import styled from 'styled-components'
import { Button, Text } from '@polydex/uikit'
import Row from '../../components/Row'

const LabelWrapper = styled.div`
  > ${Text} {
    font-size: 12px;
  }
`
function Index() {
  const [searchValue, setSearchValue] = useState('')
  const [showBalance, setShowBalance] = useState(false)

  const keyPressInput = (e: any) => {
    setSearchValue(e.target.value)
  }

  return (
    <div className="box-liquidity-pool">
      <div className="header-top-wd">
        <Row>
          <input
            type={'checkbox'}
            className="input-checkbox-farming"
            id="check-box-1"
            onClick={() => setShowBalance(!showBalance)}
          />
          <label htmlFor="check-box-1">
            <Text className="text-search-input">Show only my active liquidity pairs</Text>
          </label>
        </Row>
        <div className="box-input-search-right">
          <Row>
            <Button
              style={{ backgroundColor: '#01d374', marginRight: 10, width: 125, height: 40 }}
              onClick={() => (window.location.href = '/create')}
            >
              New pair
            </Button>
            <LabelWrapper>
              <SearchInput onChangeText={event => keyPressInput(event)} onChange={() => null} />
            </LabelWrapper>
          </Row>
        </div>
      </div>
      <TableRow searchValue={searchValue} showBalance={showBalance} />
    </div>
  )
}

export default Index
