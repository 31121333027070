import axios from 'axios'
require('dotenv').config()

const assignObjectHeader = {
  'access-control-allow-methods': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
  'access-control-allow-headers': 'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept',
  'access-control-allow-origin': '*',
  'content-type': 'application/json',
  allow: 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
  'access-control-expose-headers': 'Access-Control-*'
}

const URL_BASE_ADMIN = ''

/*axit post*/
export async function axiosPOST(Url: string, param = {}, header = {}) {
  Object.assign(header, assignObjectHeader)
  return await axios
    .post(URL_BASE_ADMIN + Url, param, { headers: header })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/*axit get*/
export async function axiosGET(Url: string, header = {}) {
  Object.assign(header, assignObjectHeader)
  return axios
    .get(URL_BASE_ADMIN + Url, { headers: header })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return Promise.reject(err)
      // return err.response;
    })
}

/*axit delete*/
export async function axiosDELETE(Url: string, header = {}) {
  Object.assign(header, assignObjectHeader)
  return await axios
    .delete(URL_BASE_ADMIN + Url, { headers: header })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/*axit put*/
export async function axiosPUT(Url: string, param = {}, header = {}) {
  Object.assign(header, assignObjectHeader)
  return await axios
    .put(URL_BASE_ADMIN + Url, param, { headers: header })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
