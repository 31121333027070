import BigNumber from 'bignumber.js'

export function bnum(val: string | number | BigNumber): BigNumber {
  return !val ? new BigNumber(0) : new BigNumber(val.toString())
}

export const denormalizeBalance = (value: string | number | BigNumber, decimals: number): BigNumber => {
  return new BigNumber(value || 0).multipliedBy(Math.pow(10, decimals)).decimalPlaces(0, BigNumber.ROUND_DOWN)
}

export const normalizeBalance = (value: string | number | BigNumber, decimals: number): BigNumber => {
  return new BigNumber(value || 0).div(Math.pow(10, decimals)).decimalPlaces(decimals, BigNumber.ROUND_DOWN)
}
