import React, { useContext } from 'react'
import { Menu as UikitMenu } from '@polydex/uikit'
import { useWeb3React } from '@web3-react/core'
import { LanguageContext } from 'hooks/LanguageContext'
import { languageList } from 'constants/localization/languages'
import useTheme from 'hooks/useTheme'
import useGetPriceData from 'hooks/useGetPriceData'
import useAuth from 'hooks/useAuth'
import links from './config'
import { PLX } from '../../constants'

const Menu: React.FC = props => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const priceData = useGetPriceData()
  const cakePriceUsd =
    priceData && priceData.data
      ? Number(priceData.data['0xc7144fa865c9f3a6836167a51531a2cc8b0ab5fd']?.price)
      : undefined
  // const profile = useGetLocalProfile()

  return (
    <UikitMenu
      links={links}
      account={account as string}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage?.code || ''}
      langs={languageList}
      setLang={setSelectedLanguage}
      cakePriceUsd={cakePriceUsd}
      {...props}
    />
  )
}

export default Menu
