import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import Pool from './Pool/index_v2'
import Swap from './Swap/index'
import AddLiquidity from './AddLiquidity'
import RemoveLiquidity from './RemoveLiquidity'
import CreatePair from './CreatePair'
import 'antd/dist/antd.css'
import '../assets/scss/app.scss'
import bs58 from 'getbs58'
import Menu from '../components/Menu'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 16px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  justify-content: flex-start;
  
  // background-image: url('/images/group-pancake.svg');
  // background-repeat: no-repeat;
  // background-position: bottom 24px center;
  // background-size: 90%;

  ${({ theme }) => theme.mediaQueries.xs} {
    background-size: auto;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    background-image: url('/arch-${({ theme }) => (theme.isDark ? 'dark' : 'light')}.svg');
    // background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    min-height: 115vh;
  }
`

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function App() {
  let inviteA = ''
  let paramsA = window.location.hash?.split('?')
  if (paramsA[1]) {
    paramsA = paramsA?.[1]?.split('=')
    inviteA = paramsA[0] === 'a' ? paramsA[1] : ''
  }
  let refByAddressStorage: string | null =
    localStorage.getItem('refByAddressStorage') || localStorage.getItem('bsAccount') || ''

  if (!refByAddressStorage && inviteA) {
    try {
      const refByAddress = `0x${bs58.decode(inviteA)}`
      localStorage.setItem('refByAddressStorage', refByAddress)
      refByAddressStorage = refByAddress
    } catch (e) {}
  }

  return (
    <Suspense fallback={null}>
      <AppWrapper>
        <Router>
          <Menu>
            <BodyWrapper>
              <Popups />
              <Web3ReactManager>
                <Switch>
                  <Route exact strict path="/swap" component={Swap} />
                  <Route exact strict path="/liquidity" component={Pool} />
                  <Route exact strict path="/create" component={CreatePair} />
                  <Route exact strict path="/add/:poolId" component={AddLiquidity} />
                  <Route exact strict path="/remove/:poolId" component={RemoveLiquidity} />
                </Switch>
              </Web3ReactManager>
              <Marginer />
            </BodyWrapper>
          </Menu>
        </Router>
      </AppWrapper>
    </Suspense>
  )
}
