import React, { useCallback, useMemo, useState } from 'react'
import AppBody from '../AppBody'
import { Dots, Wrapper } from '../Pool/styleds'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import { CreatePairTabs } from '../../components/NavigationTabs'
import { Field } from '../../state/mint/actions'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { Currency, CurrencyAmount, JSBI, Percent, Price, TokenAmount } from '@polydex/sdk'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { useCurrencyBalances } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { tryParseAmount } from '../../state/swap/hooks'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { RowBetween } from '../../components/Row'
import { AddIcon, Button, CardBody, Text } from '@polydex/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { ROUTER_ADDRESS } from '../../constants'
import { PoolPriceBar } from './PoolPriceBar'
import { currencyId } from '../../utils/currencyId'
import { useCreatePair } from '../../state/pool/hooks'
import Pane from '../../components/Pane'

export default function CreatePair() {
  const { account, chainId } = useActiveWeb3React()
  const routerAddress = chainId && ROUTER_ADDRESS[chainId]

  const createPairCallback = useCreatePair()

  const [inputAmounts, setTypedValue] = useState<{ [field in Field]: string }>({
    [Field.CURRENCY_A]: '',
    [Field.CURRENCY_B]: ''
  })
  const [currenciesId, setCurrenciesId] = useState<{ [field in Field]?: string }>({})
  // @ts-ignore
  const [currencies, setCurrencies] = useState<{ [field in Field]: Currency }>({
    CURRENCY_A: undefined,
    CURRENCY_B: undefined
  })
  const [swapFee] = useState<string>('0.2')

  // balances
  const balances = useCurrencyBalances(account ?? undefined, [
    currencies[Field.CURRENCY_A],
    currencies[Field.CURRENCY_B]
  ])
  const currencyBalances: { [field in Field]?: CurrencyAmount } = {
    [Field.CURRENCY_A]: balances[0],
    [Field.CURRENCY_B]: balances[1]
  }

  const parsedAmounts: { [field in Field]: CurrencyAmount | undefined } = {
    [Field.CURRENCY_A]: tryParseAmount(inputAmounts[Field.CURRENCY_A], currencies[Field.CURRENCY_A]),
    [Field.CURRENCY_B]: tryParseAmount(inputAmounts[Field.CURRENCY_B], currencies[Field.CURRENCY_B])
  }

  const price = useMemo(() => {
    const { [Field.CURRENCY_A]: currencyAAmount, [Field.CURRENCY_B]: currencyBAmount } = parsedAmounts
    if (currencyAAmount && currencyBAmount) {
      return new Price(currencyAAmount.currency, currencyBAmount.currency, currencyAAmount.raw, currencyBAmount.raw)
    }
    return undefined
  }, [parsedAmounts])

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field])
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0')
      }
    },
    {}
  )

  const isValid = parsedAmounts[Field.CURRENCY_A] && parsedAmounts[Field.CURRENCY_B]

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], routerAddress)
  const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], routerAddress)

  const onFieldAInput = useCallback((typedValue: string) => {
    setTypedValue(state =>
      Object.assign({}, state, {
        [Field.CURRENCY_A]: typedValue
      })
    )
  }, [])

  const onFieldBInput = useCallback((typedValue: string) => {
    setTypedValue(state =>
      Object.assign({}, state, {
        [Field.CURRENCY_B]: typedValue
      })
    )
  }, [])

  const handleCurrencyASelect = useCallback((currency: Currency) => {
    const newCurrencyId = currencyId(currency)
    setCurrencies(state =>
      Object.assign({}, state, {
        [Field.CURRENCY_A]: currency
      })
    )
    setCurrenciesId(state =>
      Object.assign({}, state, {
        [Field.CURRENCY_A]: newCurrencyId
      })
    )
  }, [])

  const handleCurrencyBSelect = useCallback((currency: Currency) => {
    const newCurrencyId = currencyId(currency)
    setCurrencies(state =>
      Object.assign({}, state, {
        [Field.CURRENCY_B]: currency
      })
    )
    setCurrenciesId(state =>
      Object.assign({}, state, {
        [Field.CURRENCY_B]: newCurrencyId
      })
    )
  }, [])

  const onCreate = useCallback(() => {
    if (!parsedAmounts[Field.CURRENCY_A] || !parsedAmounts[Field.CURRENCY_B]) return
    if (!currenciesId[Field.CURRENCY_A] || !currenciesId[Field.CURRENCY_B]) return
    if (!currencies[Field.CURRENCY_A] || !currencies[Field.CURRENCY_B]) return

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    createPairCallback(parsedAmounts, swapFee, currenciesId, currencies)
  }, [parsedAmounts, swapFee, currenciesId, currencies, createPairCallback])

  return (
    <>
      <AppBody>
        <CreatePairTabs />
        <Wrapper id='swap-page'>
          <CardBody>
            <AutoColumn gap='5px'>
              <ColumnCenter style={{ marginBottom: 10 }}>
                <Pane>
                  <AutoColumn gap='12px'>
                    <Text>{'You are the first liquidity provider.'}</Text>
                    <Text>{'The ratio of tokens you add will set the price of this pool.'}</Text>
                    <Text>{'Once you are happy with the rate click supply to review.'}</Text>
                  </AutoColumn>
                </Pane>
              </ColumnCenter>
              <CurrencyInputPanel
                value={inputAmounts[Field.CURRENCY_A]}
                onUserInput={onFieldAInput}
                onMax={() => {
                  onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
                }}
                onCurrencySelect={handleCurrencyASelect}
                showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                currency={currencies[Field.CURRENCY_A]}
                id='create-pair-input-tokena'
                showCommonBases
              />
              <ColumnCenter>
                <AddIcon color='textSubtle' />
              </ColumnCenter>
              <CurrencyInputPanel
                value={inputAmounts[Field.CURRENCY_B]}
                onUserInput={onFieldBInput}
                onCurrencySelect={handleCurrencyBSelect}
                onMax={() => {
                  onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                }}
                showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                currency={currencies[Field.CURRENCY_B]}
                id='create-pair-input-tokenb'
                showCommonBases
              />

              <ColumnCenter style={{ marginTop: 10, width: '100%' }}>
                {!account ? (
                  <ConnectWalletButton width='100%' />
                ) : (
                  <AutoColumn style={{ width: '100%' }} gap={'md'}>
                    {(approvalA === ApprovalState.NOT_APPROVED ||
                      approvalA === ApprovalState.PENDING ||
                      approvalB === ApprovalState.NOT_APPROVED ||
                      approvalB === ApprovalState.PENDING) &&
                    isValid && (
                      <RowBetween>
                        {approvalA !== ApprovalState.APPROVED && (
                          <Button
                            onClick={approveACallback}
                            disabled={approvalA === ApprovalState.PENDING}
                            style={{ width: approvalB !== ApprovalState.APPROVED ? '48%' : '100%' }}
                          >
                            {approvalA === ApprovalState.PENDING ? (
                              <Dots>Approving {currencies[Field.CURRENCY_A]?.symbol}</Dots>
                            ) : (
                              `Approve ${currencies[Field.CURRENCY_A]?.symbol}`
                            )}
                          </Button>
                        )}
                        {approvalB !== ApprovalState.APPROVED && (
                          <Button
                            onClick={approveBCallback}
                            disabled={approvalB === ApprovalState.PENDING}
                            style={{ width: approvalA !== ApprovalState.APPROVED ? '48%' : '100%' }}
                          >
                            {approvalB === ApprovalState.PENDING ? (
                              <Dots>Approving {currencies[Field.CURRENCY_B]?.symbol}</Dots>
                            ) : (
                              `Approve ${currencies[Field.CURRENCY_B]?.symbol}`
                            )}
                          </Button>
                        )}
                      </RowBetween>
                    )}

                    {currencies.CURRENCY_A &&
                    currencies.CURRENCY_B &&
                    inputAmounts[Field.CURRENCY_A] &&
                    inputAmounts[Field.CURRENCY_B] ? (
                      <div>
                        <Text
                          style={{ textTransform: 'uppercase', fontWeight: 600 }}
                          color='textSubtle'
                          fontSize='12px'
                          mb='2px'
                        >
                          {'Prices and pool share'}
                        </Text>
                        <Pane>
                          <PoolPriceBar
                            currencies={currencies}
                            poolTokenPercentage={new Percent(JSBI.BigInt(1))}
                            noLiquidity={true}
                            price={price}
                          />
                        </Pane>
                      </div>
                    ) : null}
                    <Button
                      onClick={onCreate}
                      disabled={
                        !isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED
                      }
                      width='100%'
                    >
                      {'Create'}
                    </Button>
                  </AutoColumn>
                )}
              </ColumnCenter>
            </AutoColumn>
          </CardBody>
        </Wrapper>
      </AppBody>
    </>
  )
}
