import { Interface } from '@ethersproject/abi'
import { ChainId } from '@polydex/sdk'
import V1_EXCHANGE_ABI from './v1_exchange.json'
import V1_FACTORY_ABI from './v1_factory.json'
import REWARD_SWAP_FEE_ABI from './rewardSwapFee.json'

const V1_FACTORY_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95',
  [ChainId.TESTNET]: '0xD3E51Ef092B2845f10401a0159B2B96e8B6c3D30'
}

const REWARD_SWAP_FEE: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x356209692c2430e5dfc78567a8b4ea2351063550',
  [ChainId.TESTNET]: '0x0000000000000000000000000000000000000000'
}

const V1_FACTORY_INTERFACE = new Interface(V1_FACTORY_ABI)
const V1_EXCHANGE_INTERFACE = new Interface(V1_EXCHANGE_ABI)
const REWARD_SWAP_FEE_INTERFACE = new Interface(REWARD_SWAP_FEE_ABI)

export { V1_FACTORY_ADDRESSES, V1_FACTORY_INTERFACE, V1_FACTORY_ABI, V1_EXCHANGE_INTERFACE, V1_EXCHANGE_ABI,
  REWARD_SWAP_FEE, REWARD_SWAP_FEE_INTERFACE, REWARD_SWAP_FEE_ABI }
