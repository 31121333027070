import { MenuEntry } from '@polydex/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://celodex.org/'
  },
  {
    label: 'Exchange',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Swap',
        href: '/swap'
      },
      {
        label: 'Liquidity',
        href: '/liquidity'
      },
      {
        label: 'Charts',
        href: 'https://celo.polydex.info'
      }
    ]
  },
  {
    label: 'CeloFARM',
    icon: 'FarmIcon',
    href: 'https://www.celodex.org/farms'
  },
  {
    label: 'CeloVAULT',
    icon: 'VaultIcon',
    href: '/#',
    status: {
      text: 'Soon',
      color: 'warning',
    },
  },
  {
    label: 'CeloHERO',
    icon: 'PoolIcon',
    href: 'https://celodex.org/',
    status: {
      text: 'Soon',
      color: 'warning'
    }
  },
  {
    label: 'CeloLAUNCH',
    icon: 'IfoIcon',
    href: 'https://celodex.org',
    status: {
      text: 'Soon',
      color: 'warning'
    }
  },
  {
    label: 'CeloREF',
    icon: 'ReferralIcon',
    href: 'https://celodex.org/referral'
  },
  {
    label: 'Audit by TechRate',
    icon: 'TeamBattleIcon',
    href:
      'https://github.com/polydex-fi/polydex-swap-core/blob/5fdd6b85550235800cd980ccb8fab9bd5247a7af/PolydexSwap.pdf'
  },

  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',
        href: 'https://github.com/polydex-fi'
      },
      {
        label: 'Docs',
        href: 'https://docs.polydex.fi/'
      },
      {
        label: 'Blog',
        href: 'https://polydex.medium.com'
      },
      {
        label: 'Discord',
        href: 'https://discord.gg/WtksUPQSRm'
      }
    ]
  }
]

export default config
