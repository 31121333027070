// import { useCallback, useState } from 'react'
// import { useSelector } from 'react-redux'
// import { AppState } from '../index'
// import { Field } from '../swap/actions'
// import { useSwapState } from '../swap/hooks'
// import { useCurrency } from '../../hooks/Tokens'
import {
  filterPoolsWithTokensDirect,
  filterPoolsWithTokensMultihop,
  parsePoolData,
  processEpsOfInterestMultiHop,
  processPaths,
  smartOrderRouterMultiHopEpsOfInterest
} from '../../modules/sor/src'
import { PoolDictionary, Path, EffectivePrice } from '../../modules/sor/src/types'
import { CurrencyAmount, TradeType } from '@polydex/sdk'
import BigNumber from 'bignumber.js'
import { bnum, denormalizeBalance } from '../../utils/number'
import { useMemo } from 'react'
import { useSwapPoolState } from '../swapPool/hooks'
import { isAddressEqual } from '../../utils'
import { toChecksum } from '../../modules/sor/src/helpers'

const loadPathData = (allPools: any, _tokenIn: string, _tokenOut: string): [PoolDictionary, Path[]] => {
  const tokenIn = _tokenIn.toLowerCase()
  const tokenOut = _tokenOut.toLowerCase()

  const directPools = filterPoolsWithTokensDirect(allPools, tokenIn, tokenOut)

  const [mostLiquidPoolsFirstHop, mostLiquidPoolsSecondHop, hopTokens] = filterPoolsWithTokensMultihop(
    allPools,
    tokenIn,
    tokenOut
  )

  const [pools, pathData] = parsePoolData(
    directPools,
    tokenIn,
    tokenOut,
    mostLiquidPoolsFirstHop,
    mostLiquidPoolsSecondHop,
    hopTokens
  )

  return [pools, pathData]
}

// export const useFetchPathData = (inputToken: string, outputToken: string, isRefresh: boolean = false) => {
//   const [lastInputToken, setLastInputToken] = useState<string>()
//   const [lastOutputToken, setLastOutputToken] = useState<string>()
//   const [isLoadingPaths, setIsLoadingPaths] = useState<boolean>()
//
//   const {
//     [Field.INPUT]: { currencyId: inputCurrencyId },
//     [Field.OUTPUT]: { currencyId: outputCurrencyId }
//   } = useSwapState()
//
//   const inputCurrency = useCurrency(inputCurrencyId)
//   const outputCurrency = useCurrency(outputCurrencyId)
//
//   const onChainPools = useSelector<AppState, AppState['swapPool']['onChainPools']>(state => {
//     return state.swapPool.onChainPools
//   })
//
//   return useCallback(() => {
//     if (
//       inputToken !== '' &&
//       outputToken !== '' &&
//       (lastInputToken !== inputToken || lastOutputToken !== outputToken || isRefresh)
//     ) {
//       setIsLoadingPaths(true)
//       if (!onChainPools.length) {
//         return
//       }
//       setLastInputToken(inputToken)
//       setLastOutputToken(outputToken)
//
//       // Use WMATIC address for Ether
//       if (inputToken === EtherKey)
//         inputToken = contractMetadataStore.getWethAddress()
//
//       if (outputToken === EtherKey)
//         outputToken = contractMetadataStore.getWethAddress()
//     }
//   }, [inputToken, outputToken, isRefresh, onChainPools])
// }

interface PathData {
  processedPools: PoolDictionary
  processedPathsIn: Path[]
  epsOfInterestIn: EffectivePrice[]
  processedPathsOut: Path[]
  epsOfInterestOut: EffectivePrice[]
}

export const getPathData = (_Pools: any, _InputToken: string, _OutputToken: string): PathData => {
  const [pools, pathData] = loadPathData(_Pools, _InputToken, _OutputToken)
  const maxPools = Number(process.env.REACT_APP_MAX_POOLS || 4)

  const processedPools = pools
  const processedPathsIn = processPaths(pathData, pools, 'swapExactIn')
  const epsOfInterestIn = processEpsOfInterestMultiHop(processedPathsIn, 'swapExactIn', maxPools)

  const processedPathsOut = processPaths(pathData, pools, 'swapExactOut')
  const epsOfInterestOut = processEpsOfInterestMultiHop(processedPathsOut, 'swapExactOut', maxPools)

  return {
    processedPools,
    processedPathsIn,
    epsOfInterestIn,
    processedPathsOut,
    epsOfInterestOut
  }
}

export const useFindBestSwapsMulti = (
  inputAddress: string | undefined,
  outputAddress: string | undefined,
  swapType: TradeType,
  swapAmount: CurrencyAmount | undefined,
  maxPools: number,
  returnTokenCostPerPool: BigNumber = bnum(0)
): [BigNumber, any[][]] => {
  const { onChainPools: pools } = useSwapPoolState()

  return useMemo(() => {
    if (!inputAddress || !outputAddress) {
      return [bnum(0), []]
    }

    const pathData = getPathData(pools, inputAddress, outputAddress)

    let tradeType = 'swapExactIn'
    let processedPaths = pathData.processedPathsIn
    let epsOfInterest = pathData.epsOfInterestIn

    if (swapType === TradeType.EXACT_OUTPUT) {
      tradeType = 'swapExactOut'
      processedPaths = pathData.processedPathsOut
      epsOfInterest = pathData.epsOfInterestOut
    }
    const [sorSwaps, totalReturn] = smartOrderRouterMultiHopEpsOfInterest(
      JSON.parse(JSON.stringify(pathData.processedPools)),
      processedPaths,
      tradeType,
      bnum(swapAmount?.raw.toString() || 0),
      maxPools,
      returnTokenCostPerPool,
      epsOfInterest,
      3
    )
    console.log('__best_swaps__', [totalReturn?.toString(), sorSwaps])

    return [totalReturn, sorSwaps]
  }, [pools, inputAddress, outputAddress, swapType, swapAmount, maxPools, returnTokenCostPerPool])
}

const toPoolData = (pool: any, tokenIn?: string, tokenOut?: string) => {
  if (!Array.isArray(pool.tokens) || !pool.tokens.length) {
    return {}
  }
  let [tI, tO] = pool.tokens
  if (tokenIn) {
    tI = pool.tokens.find((t: any) => isAddressEqual(t.address, tokenIn))
  }
  if (tokenOut) {
    tO = pool.tokens.find((t: any) => isAddressEqual(t.address, tokenOut))
  }

  const obj = Object.assign({}, pool)

  // if (tI.balance > 0 && tO.balance > 0) {
  if (tI && tO) {
    Object.assign(obj, {
      tokenIn: tI,
      tokenOut: tO,
      decimalsIn: tI.decimals,
      decimalsOut: tO.decimals,
      balanceIn: bnum(tI.balance),
      balanceOut: bnum(tO.balance),
      weightIn: denormalizeBalance(bnum(tI.denormWeight).div(pool.totalWeight), 18),
      weightOut: denormalizeBalance(bnum(tO.denormWeight).div(pool.totalWeight), 18)
    })
  }
  Object.assign(obj, {
    id: toChecksum(pool.id),
    totalWeight: bnum(pool.totalWeight).toFixed(),
    totalShares: bnum(pool.totalShares),
    collectedFee: parseFloat(pool.collectedFee) || 0
  })
  if (pool.controller) {
    obj.controller = toChecksum(pool.controller)
  }
  return obj
}

export const useFormatSorSwaps = (sorSwaps: any[][]) => {
  const { onChainPools: allPools } = useSwapPoolState()

  return useMemo(() => {
    const formattedSorSwaps: any[] = []

    for (let i = 0; i < sorSwaps.length; i++) {
      const sequence: any[] = sorSwaps[i]
      const sorMultiSwap: any = { sequence: [] }

      for (let j = 0; j < sequence.length; j++) {
        const swap = sequence[j]

        // Even if we use on-chain backup we need to get decimals from backup as not retrieved by SOR
        let pool = allPools?.find(p => isAddressEqual(p.id, swap.pool))
        pool = toPoolData(pool, swap.tokenIn, swap.tokenOut)

        const multiSwap: any = {
          pool: swap.pool,
          tokenInParam: swap.tokenIn,
          tokenOutParam: swap.tokenOut,
          maxPrice: swap.maxPrice,
          swapAmount: swap.swapAmount,
          limitReturnAmount: swap.limitReturnAmount,
          id: pool.id,
          tokenIn: pool.tokenIn,
          tokenOut: pool.tokenOut,
          decimalsIn: pool.decimalsIn,
          decimalsOut: pool.decimalsOut,
          balanceIn: pool.balanceIn,
          balanceOut: pool.balanceOut,
          weightIn: pool.weightIn,
          weightOut: pool.weightOut,
          swapFee: pool.swapFee,
          version: pool.version
        }
        sorMultiSwap.sequence.push(multiSwap)
      }
      formattedSorSwaps.push(sorMultiSwap)
    }

    return formattedSorSwaps
  }, [sorSwaps, allPools])
}
