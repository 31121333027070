import { useCallback, useEffect, useState } from 'react'
import { Pool } from '../../constants/pool'
import { fetchPool } from '../../utils/subgraph'
import { useActiveWeb3React } from '../../hooks'
import { Currency, CurrencyAmount, MATIC } from '@polydex/sdk'
import { Field } from '../mint/actions'
import { calculateGasMargin, getRouterContract, isAddressEqual } from '../../utils'
import isZero from '../../utils/isZero'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../transactions/hooks'
import { normalizeBalance } from '../../utils/number'
import useInterval from '../../hooks/useInterval'
import { getAllPoolDataOnChain } from '../../modules/sor/src'
import { MULTICALL_NETWORKS } from '../../constants/multicall'
import ApiAxios from '../../service/ApiAxios'

function parseSingleOnChainPoolsData(pool: any): any {
  if (!pool) {
    return {}
  }
  const tokens = Array.isArray(pool.tokens)
    ? pool.tokens.map((t: any) => ({
        ...t,
        balance: normalizeBalance(t.balance, t.decimals),
        denormWeight: normalizeBalance(t.denormWeight, 18)
      }))
    : []
  return {
    ...pool,
    totalShares: normalizeBalance(pool.totalShares, 18),
    totalWeight: normalizeBalance(pool.totalWeight, 18),
    tokens
  }
}

export const usePool = (poolId?: string): Pool => {
  const { chainId, library } = useActiveWeb3React()
  const [pool, setPool] = useState<Pool>({} as Pool)
  const multicallAddress = chainId && MULTICALL_NETWORKS[chainId]

  useInterval(
    async () => {
      if (!multicallAddress || !library) return
      const data = await getAllPoolDataOnChain({ pools: [pool as any] }, multicallAddress, library)
      const poolOnChain = Array.isArray(data?.pools) ? data.pools[0] : undefined
      if (poolOnChain) {
        const parsed = JSON.parse(JSON.stringify(parseSingleOnChainPoolsData(poolOnChain)))
        parsed.swapFee = normalizeBalance(parsed.swapFee, 18).toString()
        setPool(pool => ({
          ...pool,
          ...parsed,
          tokens: pool.tokens.map(t => {
            const tokenFound = parsed.tokens?.find((t2: any) => isAddressEqual(t.address, t2.address))
            return Object.assign({}, t, tokenFound || {})
          })
        }))
      }
    },
    pool?.id && multicallAddress && library ? 15000 : null
  )

  useEffect(() => {
    if (poolId) {
      const fetcher = async () => {
        const pool = await fetchPool(poolId, chainId)
        pool && setPool(pool)
      }

      fetcher()
    }
  }, [poolId, chainId])

  return pool
}

export const useCreatePair = () => {
  const { chainId, account, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  return useCallback(
    async (
      amounts: { [field in Field]: CurrencyAmount },
      swapFee: string,
      currenciesId: { [field in Field]: string },
      currencies: { [field in Field]: Currency }
    ) => {
      if (!chainId || !library || !account) return
      const router = getRouterContract(chainId, library, account)

      const tokenBIsETH = currencies[Field.CURRENCY_B] === MATIC
      const oneTokenIsETH = currencies[Field.CURRENCY_A] === MATIC || tokenBIsETH
      // const _swapFee = bnum(swapFee)
      //   .multipliedBy(100)
      //   .toString()

      let methodName: string
      let args: any[]
      let value = '0x0'
      console.log('oneTokenIsETH', oneTokenIsETH);
      if (oneTokenIsETH) {
        methodName = 'createPairETH'
        args = [
          tokenBIsETH ? currenciesId[Field.CURRENCY_A] : currenciesId[Field.CURRENCY_B],
          (tokenBIsETH ? amounts[Field.CURRENCY_A] : amounts[Field.CURRENCY_B]).raw.toString(),

          account
        ]
        value = (tokenBIsETH ? amounts[Field.CURRENCY_B] : amounts[Field.CURRENCY_A]).raw.toString()
        console.log('args', args, value)
      } else {
        methodName = 'createPair'
        args = [
          currenciesId[Field.CURRENCY_A],
          currenciesId[Field.CURRENCY_B],
          amounts[Field.CURRENCY_A].raw.toString(),
          amounts[Field.CURRENCY_B].raw.toString(),
          '50',
          25,
          account
        ]
      }
      const options = !value || isZero(value) ? {} : { value }

      return router.estimateGas[methodName](...args, options)
        .then(gasEstimate => {
          return router[methodName](...args, { value, gasLimit: calculateGasMargin(gasEstimate) }).then(
            async (response: TransactionResponse) => {
              await ApiAxios.LiquidityPool.scanPool()
              addTransaction(response, {
                summary: `${methodName} ${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol}`
              })
              return response.hash
            }
          )
        })
        .catch((gasError: any) => {
          console.error('Estimate gas error', methodName, args, value, gasError)
          return router.callStatic[methodName](...args, options)
            .then(result => {
              console.error('Unexpected successful call after failed estimate gas', gasError, result)
            })
            .catch(callError => {
              console.error('Call threw error', callError)
            })
        })
    },
    [chainId, library, account, addTransaction]
  )
}
