import React from 'react'
import { commify } from '@ethersproject/units'
import iconLink from '../../../assets/images/pool/icon-link.png'
import { ChainId, Token } from '@polydex/sdk'
import { useTokenBalance } from '../../../state/wallet/hooks'
import { useActiveWeb3React } from '../../../hooks'
import tickCorrect from '../../../assets/images/pool/tick-correct.png'
import { Button, Text } from '@polydex/uikit'
import DoubleCurrencyLogo from '../../../components/DoubleLogo'
import Web3 from 'web3'

export default function TrRowTable(props: any) {
  const item = props?.item

  const tokenSymbol = item?.symbol
  const urlIn = Web3.utils.toChecksumAddress(item?.token0)
  const urlOut = Web3.utils.toChecksumAddress(item?.token1)
  const tokenIn = new Token(ChainId.MAINNET, urlIn, 18)
  const tokenOut = new Token(ChainId.MAINNET, urlOut, 18)

  const { account } = useActiveWeb3React()
  const token = new Token(ChainId.MAINNET, item?.address, 18)
  const tokenBalance = useTokenBalance(account ?? undefined, token)
  let rowSearchHidden = false
  if (
    (props?.showBalance && tokenBalance && Number(tokenBalance?.toSignificant()) === 0) ||
    (!account && props?.showBalance)
  ) {
    rowSearchHidden = true
  }

  if (props?.searchValue && item?.symbol.indexOf(props?.searchValue?.toUpperCase()) === -1) {
    rowSearchHidden = true
  }

  const poolDayDatas = item?.['pool-poolDayDatas']?.[0] ? item?.['pool-poolDayDatas']?.[0]?.dailyVolumeUSD : 0
  const disableRemoveBtn = tokenBalance && Number(tokenBalance?.toSignificant()) > 0
  return (
    <div className={`item-coin ${rowSearchHidden ? 'hidden-row' : ''}`}>
      <div className="image-list-coin">
        <div className="box-list-image-coin">
          <DoubleCurrencyLogo currency0={tokenIn} currency1={tokenOut} size={30} />
          {item?.isWhiteList === 1 && (
            <div className="box-tick-correct-white-list">
              <img src={tickCorrect} className="tick-style-white-list" alt={''} />
            </div>
          )}
        </div>
        <Text>{tokenSymbol}</Text>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a className="box-icon-link-pool" href={`https://explorer.celo.org/token/${item?.address}`} target="_blank">
          <img src={iconLink} className="icon-link-pool" alt={''} />
        </a>
      </div>
      <div className="item-column-1">
        <div className="number-style label-display-flex-mobile">
          <span className="label-left-mobile">My Liquidity: </span>
          <Text>{tokenBalance && Number(tokenBalance?.toSignificant()) > 0 ? tokenBalance.toFixed(6) : '--'}</Text>
        </div>
      </div>
      <div className="item-column-1">
        <div className="number text-white text-bold label-display-flex-mobile">
          <span className="label-left-mobile">Liquidity: </span>
          <Text>{item?.liquidity > 0 ? `$${commify(item?.liquidity.toFixed(0))}` : '--'}</Text>
        </div>
      </div>
      <div className="item-column-1">
        <div className="number-style label-display-flex-mobile">
          <span className="label-left-mobile">24h Volume: </span>
          <Text>
            {parseFloat(poolDayDatas) > 0
              ? '$' + commify(poolDayDatas.toFixed(parseFloat(poolDayDatas) > 1 ? 0 : 4))
              : '--'}
          </Text>
        </div>
      </div>
      <div className="item-column-2">
        <div className="box-btn-liquidity">
          <Button
            disabled={!disableRemoveBtn}
            style={{ marginRight: 6, minWidth: 133 }}
            onClick={() => (window.location.href = `/remove/${item?.address}`)}
          >
            Remove LP
          </Button>
          <Button
            style={{ backgroundColor: '#01d374', minWidth: 133 }}
            onClick={() => (window.location.href = `/add/${item?.address}`)}
          >
            Add LP
          </Button>
        </div>
      </div>
    </div>
  )
}
