import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import { useCallback, useState } from 'react'
import { setPoolsList, setPoolsOnChain } from './actions'
import { getAllPoolDataOnChain, getAllPublicSwapPools } from '../../modules/sor/src'
import { useActiveWeb3React } from '../../hooks'
import { MULTICALL_NETWORKS } from '../../constants/multicall'

export function useSwapPoolState(): AppState['swapPool'] {
  return useSelector<AppState, AppState['swapPool']>(state => state.swapPool)
}

export const useFetchSwapPoolsOnChainBalances = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { library, chainId } = useActiveWeb3React()
  const [fetchingOnChain, setFetchingOnChain] = useState<boolean>(false)

  const multicallAddress = chainId && MULTICALL_NETWORKS[chainId]

  const { poolsList } = useSwapPoolState()

  const _update = useCallback(
    (pools?: Array<any>) => {
      const _pools = pools ?? poolsList
      if (!fetchingOnChain && library && _pools?.length) {
        setFetchingOnChain(true)
        const _update = async () => {
          let onchainPools: Array<any>
          try {
            const poolsData = await getAllPoolDataOnChain({ pools: _pools }, multicallAddress || '', library, '')
            onchainPools = Array.isArray(poolsData.pools) ? JSON.parse(JSON.stringify(poolsData.pools)) : []
            console.log('onchainPools', onchainPools)
          } catch (error) {
            onchainPools = []
          }
          dispatch(setPoolsOnChain({ pools: onchainPools }))
          setFetchingOnChain(false)
        }

        _update()
      }
    },
    [dispatch, fetchingOnChain, library, poolsList, multicallAddress]
  )

  return _update
}

export const useFetchSwapPools = () => {
  const dispatch = useDispatch<AppDispatch>()
  const fetchOnChain = useFetchSwapPoolsOnChainBalances()

  return useCallback(() => {
    async function _fetchPools() {
      const { pools } = await getAllPublicSwapPools()
      console.log('useFetchSwapPools', pools)
      if (Array.isArray(pools)) {
        dispatch(setPoolsList({ pools }))
        fetchOnChain(pools)
      }
    }

    _fetchPools()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])
}
